@import "functions";

@media (max-width: 1665px) {
	.poll-table-actions {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		& > button {
			margin-left: 0;
			&:not(:last-child) {
				margin-bottom: 0.5rem
			}
		}
	}
}

@media (max-width: 1050px) {
	.box {
		&.newsone,
		&.contacts,
		&.job-descriptions,
		&.events,
		&.notifications-list,
		&.polls-list,
		&.profile,
		&.medical,
		&.applications,
		&.education,
		&.bonus-program {
			height: calcHeight(100px);
		}
		&.news, &.pollone, &.user-agreement {
			height: calcHeight(127px);
		}
		&.profile-wrap {
			height: calcHeight(154px);
		}
		&.register {
			height: calcHeight(145px);
		}
		&.partners {
			height: calcHeight(98px);
		}
	}

	// чтобы был всегда снизу
	footer {
	  position: fixed !important; // fixed чтоб не скрывалось при появлении url-бара в мобильном браузере
	  width: 100%;
	  bottom: 0;
	}

	.form.d-flex {
		height: calc(100vh - 109px);
	}
}

@media (max-height: 500px) {
	.form.d-flex {
		height: initial;
	}
}
@media (min-width: 992px) {
	.page-header.user-agreement-header {
		margin-top: 0;
	}
}

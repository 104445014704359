@charset "utf-8";
html, body, #root {
  width: 100%;
  height: 100%;
}
body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background: #223a90;
}
body.enter {
  background: #223a90 url("../img/wave.png") center 47px no-repeat;
  background-size: contain;
}
.box {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow: hidden !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
}
.box section {
  width: 100%;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
.box section.section-vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
}
.box section.section-vertical .container {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  -webkit-align-self: center;
  -ms-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
header {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
footer {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
section {
  padding: 30px 0;
}
section.section-inline + .section-inline {
  padding-top: 0;
}
.margin-tb {
  margin-top: 30px;
  margin-bottom: 30px;
}
.floater {
  margin: 0 -7.5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.floater .floater-one {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  max-width: 100%;
  margin: 0 7.5px;
}
body {
  position: relative;
  color: #686868;
  font-size: calc((100vw - 320px)/(1920 - 320) * (16 - 14) + 14px);
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin-bottom: 1em;
  color: #000000;
}
h1,
.h1 {
  font-weight: 700;
  font-size: 300%;
}
h2,
.h2 {
  font-weight: 700;
  font-size: 200%;
}
h3,
.h3 {
  margin-bottom: 0.666667em;
  line-height: 1.333334;
  font-size: 128.5715%;
  font-weight: 700;
}
h4,
.h4 {
  font-size: 85.714%;
  font-weight: 400;
  opacity: 0.5;
}
small {
  line-height: 1.3;
  font-size: 80%;
}
a {
  text-decoration: none;
  color: #6e86dd;
}
a:hover {
  text-decoration: none;
  color: #2c4bb9;
}
.desc {
  color: #808080;
  font-size: 0.875rem;
}
.pagination {
  margin: 30px 0 0;
}
.page-link {
  color: #6e86dd;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: rgba(161, 173, 205, 0.5);
}
.page-link:hover {
  z-index: 0;
  color: #2c4bb9;
  background: #fafafa;
}
.page-item.active .page-link {
  z-index: 1;
  border-color: #223a90;
  background-color: #223a90;
}
.page-item .page-link {
  color: #223a90;
}
fieldset {
  margin: 30px 0;
}
fieldset legend {
  margin-bottom: 15px;
}
.form-group:after {
  display: table;
  content: '';
  clear: both;
}
.form-group label {
  font-size: calc((100vw - 320px)/(1920 - 320) * (16 - 14) + 14px);
  font-weight: 400;
}
.form-group .form-control {
  color: #000000;
  font-size: calc((100vw - 320px)/(1920 - 320) * (16 - 14) + 14px);
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  border: 1px solid rgba(161, 173, 205, 0.5);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-group .form-control:not([size]):not([multiple]):not(textarea) {
  height: 18px;
}
.form-group .form-control:hover {
  background: #fafafa;
  border-color: rgba(69, 85, 129, 0.5);
}
.form-group .form-control:focus {
  background: #fafafa;
  border-color: rgba(56, 70, 106, 0.5);
}
.form-group .form-control::-webkit-input-placeholder,
.form-group .form-control:placeholder-shown {
  color: #A1ADCD;
}
.form-group .form-control:-ms-input-placeholder,
.form-group .form-control:placeholder-shown {
  color: #A1ADCD;
}
.form-group .form-control::-ms-input-placeholder,
.form-group .form-control:placeholder-shown {
  color: #A1ADCD;
}
.form-group .form-control::placeholder,
.form-group .form-control:placeholder-shown {
  color: #A1ADCD;
}
.form-group textarea.form-control {
  min-height: 3.8em;
}
.form-group .form-control-feedback,
.form-group .help-block {
  max-width: 100%;
  margin: 18px 0 -24px;
  display: none;
  padding: 0;
  line-height: 1.3;
  color: #686868;
  background: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.form-group[class*="has-"] {
  cursor: default;
}
.form-group[class*="has-"] .form-control-feedback,
.form-group[class*="has-"] .help-block {
  display: block;
}
.form-group.has-success .form-control {
  border-color: #32A737;
}
.form-group.has-success .form-control-feedback,
.form-group.has-success .help-block {
  color: #32A737;
}
.form-group.has-warning .form-control {
  border-color: #E18700;
}
.form-group.has-warning .form-control-feedback,
.form-group.has-warning .help-block {
  color: #E18700;
}
.form-group.has-danger .form-control,
.form-group.has-error .form-control {
  border-color: #F25C62;
}
.form-group.has-danger .form-control-feedback,
.form-group.has-error .form-control-feedback,
.form-group.has-danger .help-block,
.form-group.has-error .help-block {
  color: #F25C62;
}
.hidden-checkbox {
  display: none;
}
.help-block {
  font-size: 85.714%;
}
.form-feedback {
  position: relative;
  margin: 30px 0;
  padding: 20px 15px 15px;
  color: #fff;
  font-size: 0.725em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.form-feedback.has-danger {
  background: #F25C62;
}
.form-feedback .form-feedback-title {
  top: -1em;
  padding: 3px 10px;
  position: absolute;
  color: #686868;
  color: #F25C62;
  font-weight: 700;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #F25C62;
  background: #fff;
}
.form-feedback ul,
.form-feedback li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}
.form-control[disabled] {
  color: #333333;
  border-color: #e6e6e6 !important;
  background: #e6e6e6 !important;
  -webkit-opacity: 0.5 !important;
  -khtml-opacity: 0.5 !important;
  -moz-opacity: 0.5 !important;
  opacity: 0.5 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50) !important;
  filter: alpha(opacity=50) !important;
}
.form-material {
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  padding-bottom: 7px;
  position: relative;
  padding-top: 8px;
  overflow: hidden;
  margin: 0 0 2rem;
  z-index: 1;
}
.form-material label {
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 37px;
  color: #000000;
  font-size: 100%;
  overflow: hidden;
  line-height: 37px;
  position: absolute;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-material .form-control {
  position: relative;
  height: 18px;
  padding: 0;
  color: #000000;
  background: transparent !important;
  margin-top: 6px;
  border: 0;
  z-index: 2;
  font-weight: 700;
  -webkit-transition: all 0.3s ease 0.3s ease;
  -moz-transition: all 0.3s ease 0.3s ease;
  -o-transition: all 0.3s ease 0.3s ease;
  transition: all 0.3s ease 0.3s ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-material .form-control:not(textarea) {
  height: 18px;
}
.form-material textarea.form-control {
  font-weight: 400;
  height: auto;
}
.form-material:focus label,
.form-material.value label {
  height: auto;
  font-size: 10px;
  line-height: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.form-material.disabled label {
  color: rgba(0, 0, 0, 0.5);
}
.form-material .form-control-feedback,
.form-material .help-block {
  margin: 0 0 -6px;
}
.form-check {
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
}
.form-check + .form-check {
  margin-top: 10px;
}
.form-check.inline {
  padding-right: 15px;
  display: inline-block;
}
.form-check input.form-check-input {
  display: none;
}
.form-check input.form-check-input + .form-check-label {
  position: relative;
  padding-left: 30px;
  color: #686868;
  font-size: calc((100vw - 320px)/(1920 - 320) * (16 - 14) + 14px);
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
  min-height: 21px;
  line-height: 21px;
}
.form-check input.form-check-input + .form-check-label:before,
.form-check input.form-check-input + .form-check-label:after {
  position: absolute;
  top: 1px;
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-check input.form-check-input + .form-check-label small,
.form-check input.form-check-input + .form-check-label .small {
  line-height: 1.3;
}
.form-check input.form-check-input[type="checkbox"] + .form-check-label:before {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 0 2px #223a90;
  -moz-box-shadow: inset 0 0 0 2px #223a90;
  box-shadow: inset 0 0 0 2px #223a90;
}
.form-check input.form-check-input[type="checkbox"] + .form-check-label:after {
  top: 6px;
  left: 5px;
  width: 10px;
  height: 6px;
  -webkit-transform: rotate(-45deg) translate(15px, -15px);
  -moz-transform: rotate(-45deg) translate(15px, -15px);
  -ms-transform: rotate(-45deg) translate(15px, -15px);
  -o-transform: rotate(-45deg) translate(15px, -15px);
  transform: rotate(-45deg) translate(15px, -15px);
  background: none;
  border-left: 2px solid #223a90;
  border-bottom: 2px solid #223a90;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.form-check input.form-check-input[type="radio"] + .form-check-label:before {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: inset 0 0 0 2px #223a90;
  -moz-box-shadow: inset 0 0 0 2px #223a90;
  box-shadow: inset 0 0 0 2px #223a90;
}
.form-check input.form-check-input[type="radio"] + .form-check-label:after {
  display: none;
}
.form-check input.form-check-input:checked[type="checkbox"] + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 2px #223a90;
  -moz-box-shadow: inset 0 0 0 2px #223a90;
  box-shadow: inset 0 0 0 2px #223a90;
}
.form-check input.form-check-input[type="checkbox"][disabled] + .form-check-label:before {
  box-shadow: inset 0 0 0 2px #73818f;
}
.form-check input.form-check-input:checked[type="checkbox"][disabled] + .form-check-label:before {
  box-shadow: inset 0 0 0 2px #73818f;
}
.form-check input.form-check-input:checked[type="checkbox"][disabled] + .form-check-label:after {
  border-left-color: #73818f;
  border-bottom-color: #73818f;
}
.form-check input.form-check-input:checked[type="checkbox"] + .form-check-label:after {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transform: rotate(-45deg) translate(0, 0);
  -moz-transform: rotate(-45deg) translate(0, 0);
  -ms-transform: rotate(-45deg) translate(0, 0);
  -o-transform: rotate(-45deg) translate(0, 0);
  transform: rotate(-45deg) translate(0, 0);
}
.form-check input.form-check-input:checked[type="radio"] + .form-check-label:before {
  -webkit-box-shadow: inset 0 0 0 2px #223a90, inset 0 0 0 5px #ffffff;
  box-shadow: inset 0 0 0 2px #223a90, inset 0 0 0 5px #ffffff;
  background: #223a90;
}
.form-check.switch label {
  padding-left: 60px;
  line-height: 25px;
}
.form-check.switch label:before {
  -webkit-box-shadow: inset 0 0 0 2px #D0D6E6, inset -20px 0 0 2px #D0D6E6;
  box-shadow: inset 0 0 0 2px #D0D6E6, inset -20px 0 0 2px #D0D6E6;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  position: absolute;
  background: #ffffff;
  height: 31px;
  width: 51px;
  content: '';
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.form-check.switch input {
  display: none;
}
.form-check.switch input:checked + label:before {
  -webkit-box-shadow: inset 20px 0 0 2px #223a90, inset 0 0 0 2px #223a90;
  box-shadow: inset 20px 0 0 2px #223a90, inset 0 0 0 2px #223a90;
}
.content a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #4B79C5;
}
.content a:hover {
  color: #294a81;
}
.content ul,
.content ol {
  list-style: none;
  padding: 0;
}
.content ul li + li,
.content ol li + li {
  margin-top: 0.35em;
}
.content ul li {
  padding-left: 15px;
  position: relative;
}
.content ul li:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #223a90;
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  top: 0.5em;
  left: 0;
}
.content ol {
  counter-set: list;
  counter-reset: list;
}
.content ol li {
  counter-increment: list;
}
.content ol li:before {
  content: counter(list) '. ';
  color: #223a90;
}
.col-user-enterprises {
  padding-left: 0;
  margin-top: 25px;
  padding-bottom: 15px;
}
.text-clip {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.icon:before {
  vertical-align: middle;
}
.map {
  padding-bottom: 34%;
  position: relative;
  display: block;
  margin: 30px 0;
  width: 100%;
}
.map > * {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.videoWrapper {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding-bottom: 56.25%;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal .modal-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 0;
}
.modal .modal-header {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  border: 0;
}
.modal .modal-header .close {
  top: 0;
  left: 100%;
  padding: 0;
  width: 20px;
  margin: 16px;
  height: 20px;
  outline: none;
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.modal .modal-header .close:after,
.modal .modal-header .close:before {
  content: '';
  display: block;
  margin: 0 -2px;
  height: 2px;
  background: #ffffff;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.modal .modal-header .close:after {
  margin-top: -2px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal .modal-header .close:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal .modal-header .close:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.modal .modal-body {
  line-height: 1.14286;
  overflow: auto;
}
.modal .modal-footer {
  display: block;
  border: 0;
}
.modal .modal-footer .buttons-group {
  margin-top: 0;
}
.modal .section-block {
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.modal .buttons-group {
  margin-bottom: -7.5px;
}
.modal-backdrop.show {
  background: rgba(34, 58, 144, 0.75);
}
.maintains {
  text-align: center;
}
.maintains svg {
  width: 600px;
  max-width: 100%;
  margin-bottom: 2em;
}
.maintains svg .maintainse_bg {
  fill: #223a90;
}
.maintains svg .maintainse_code_bg {
  fill: #686868;
}
.maintains svg .maintainse_border {
  stroke: #686868;
  fill: #223a90;
}
.p404 {
  text-align: center;
}
.dropzone {
  padding: 30px;
  text-align: center;
  border: 2px dashed rgba(161, 173, 205, 0.5);
}
.dropzone.dropzone-multiple {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.dropzone.dz-drag-hover {
  border-color: #2c4bb9;
}
@media (max-width: 991px) {
  .maintains {
    max-height: 100vh;
    font-size: 2.5vh;
  }
  .maintains h1 {
    margin-bottom: 2vh;
    font-size: 4vh;
  }
  .maintains .desc {
    font-size: 2vh;
  }
  .maintains svg {
    max-height: 30vh;
    width: auto;
  }
}
.btn {
  font-weight: 700;
  font-size: 100%;
  line-height: 2;
}
.btn.btn-default {
  color: #fff;
  border-color: #ffffff;
  background-color: #ffffff;
  cursor: pointer;
  color: #223a90 !important;
}
.btn.btn-default:hover,
.btn.btn-default:active:not(:disabled):not(.disabled) {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}
.btn.btn-default:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-default:focus {
  border-color: #ffffff;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
}
.btn.btn-default.disabled,
.btn.btn-default:disabled {
  color: #fff;
  border-color: #ffffff;
  background-color: #ffffff;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-primary {
  color: #fff;
  border-color: #223a90;
  background-color: #223a90;
  cursor: pointer;
}
.btn.btn-primary:hover,
.btn.btn-primary:active:not(:disabled):not(.disabled) {
  border-color: #182967;
  background-color: #182967;
}
.btn.btn-primary:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-primary:focus {
  border-color: #223a90;
  background-color: #223a90;
  -webkit-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
}
.btn.btn-primary.disabled,
.btn.btn-primary:disabled {
  color: #fff;
  border-color: #223a90;
  background-color: #223a90;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-success {
  color: #fff;
  border-color: #32A737;
  background-color: #32A737;
  cursor: pointer;
}
.btn.btn-success:hover,
.btn.btn-success:active:not(:disabled):not(.disabled) {
  border-color: #26802a;
  background-color: #26802a;
}
.btn.btn-success:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-success:focus {
  border-color: #32A737;
  background-color: #32A737;
  -webkit-box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
  box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
}
.btn.btn-success.disabled,
.btn.btn-success:disabled {
  color: #fff;
  border-color: #32A737;
  background-color: #32A737;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-info {
  color: #fff;
  border-color: #4B79C5;
  background-color: #4B79C5;
  cursor: pointer;
}
.btn.btn-info:hover,
.btn.btn-info:active:not(:disabled):not(.disabled) {
  border-color: #3661a7;
  background-color: #3661a7;
}
.btn.btn-info:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-info:focus {
  border-color: #4B79C5;
  background-color: #4B79C5;
  -webkit-box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
  box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
}
.btn.btn-info.disabled,
.btn.btn-info:disabled {
  color: #fff;
  border-color: #4B79C5;
  background-color: #4B79C5;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-warning {
  color: #fff;
  border-color: #E18700;
  background-color: #E18700;
  cursor: pointer;
}
.btn.btn-warning:hover,
.btn.btn-warning:active:not(:disabled):not(.disabled) {
  border-color: #ae6800;
  background-color: #ae6800;
}
.btn.btn-warning:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-warning:focus {
  border-color: #E18700;
  background-color: #E18700;
  -webkit-box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
  box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
}
.btn.btn-warning.disabled,
.btn.btn-warning:disabled {
  color: #fff;
  border-color: #E18700;
  background-color: #E18700;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-danger {
  color: #fff;
  border-color: #F25C62;
  background-color: #F25C62;
  cursor: pointer;
}
.btn.btn-danger:hover,
.btn.btn-danger:active:not(:disabled):not(.disabled) {
  border-color: #ee2d35;
  background-color: #ee2d35;
}
.btn.btn-danger:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-danger:focus {
  border-color: #F25C62;
  background-color: #F25C62;
  -webkit-box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
  box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
}
.btn.btn-danger.disabled,
.btn.btn-danger:disabled {
  color: #fff;
  border-color: #F25C62;
  background-color: #F25C62;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-link {
  font-weight: 400;
}
.btn-link:hover {
  color: #2c4bb9;
  text-decoration: underline;
}
.btn.btn-outline-primary {
  color: #223a90;
  background-color: transparent;
  border-color: #223a90;
  cursor: pointer;
}
.btn.btn-outline-primary.btn--clear-filter:active {
  color: #fff;
  background-color: #223a90;
  border-color: #223a90;
}
@media (hover: hover) and (pointer: fine) {
  .btn.btn-outline-primary:hover,
  .btn.btn-outline-primary:active:not(:disabled):not(.disabled) {
    color: #fff;
    background-color: #223a90;
    border-color: #223a90;
  }
}
.btn.btn-outline-primary:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-primary:focus {
  border-color: #223a90;
  -webkit-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
}
.btn.btn-outline-primary:focus:not(:focus-visible) {
  box-shadow: none;
}
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:disabled {
  color: #223a90;
  background-color: transparent;
  border-color: #223a90;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-success {
  color: #32A737;
  background-color: transparent;
  border-color: #32A737;
  cursor: pointer;
}
.btn.btn-outline-success:hover,
.btn.btn-outline-success:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #32A737;
  border-color: #32A737;
}
.btn.btn-outline-success:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-success:focus {
  border-color: #32A737;
  -webkit-box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
  box-shadow: 0 0 0 3px rgba(50, 167, 55, 0.3);
}
.btn.btn-outline-success.disabled,
.btn.btn-outline-success:disabled {
  color: #32A737;
  background-color: transparent;
  border-color: #32A737;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-info {
  color: #4B79C5;
  background-color: transparent;
  border-color: #4B79C5;
  cursor: pointer;
}
.btn.btn-outline-info:hover,
.btn.btn-outline-info:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #4B79C5;
  border-color: #4B79C5;
}
.btn.btn-outline-info:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-info:focus {
  border-color: #4B79C5;
  -webkit-box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
  box-shadow: 0 0 0 3px rgba(75, 121, 197, 0.3);
}
.btn.btn-outline-info.disabled,
.btn.btn-outline-info:disabled {
  color: #4B79C5;
  background-color: transparent;
  border-color: #4B79C5;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-warning {
  color: #E18700;
  background-color: transparent;
  border-color: #E18700;
  cursor: pointer;
}
.btn.btn-outline-warning:hover,
.btn.btn-outline-warning:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #E18700;
  border-color: #E18700;
}
.btn.btn-outline-warning:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-warning:focus {
  border-color: #E18700;
  -webkit-box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
  box-shadow: 0 0 0 3px rgba(225, 135, 0, 0.3);
}
.btn.btn-outline-warning.disabled,
.btn.btn-outline-warning:disabled {
  color: #E18700;
  background-color: transparent;
  border-color: #E18700;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-danger {
  color: #F25C62;
  background-color: transparent;
  border-color: #F25C62;
  cursor: pointer;
}
.btn.btn-outline-danger:hover,
.btn.btn-outline-danger:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #F25C62;
  border-color: #F25C62;
}
.btn.btn-outline-danger:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-danger:focus {
  border-color: #F25C62;
  -webkit-box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
  box-shadow: 0 0 0 3px rgba(242, 92, 98, 0.3);
}
.btn.btn-outline-danger.disabled,
.btn.btn-outline-danger:disabled {
  color: #F25C62;
  background-color: transparent;
  border-color: #F25C62;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.btn-outline-light {
  color: #223a90;
  background-color: transparent;
  border-color: #223a90;
  cursor: pointer;
  border-color: rgba(161, 173, 205, 0.5);
}
.btn.btn-outline-light:hover,
.btn.btn-outline-light:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #223a90;
  border-color: #223a90;
}
.btn.btn-outline-light:active:not(:disabled):not(.disabled) {
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-outline-light:focus {
  border-color: #223a90;
  -webkit-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  -moz-box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
  box-shadow: 0 0 0 3px rgba(34, 58, 144, 0.3);
}
.btn.btn-outline-light.disabled,
.btn.btn-outline-light:disabled {
  color: #223a90;
  background-color: transparent;
  border-color: #223a90;
  -webkit-opacity: 0.25;
  -khtml-opacity: 0.25;
  -moz-opacity: 0.25;
  opacity: 0.25;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
  filter: alpha(opacity=25);
  cursor: default;
}
.btn.w-100.btn-table {
  min-width: 245px;
}
.buttons-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 22.5px -7.5px 0;
}
.buttons-group-password {
  justify-content: space-between;
}
.buttons-group .btn {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  margin: 7.5px;
}
.buttons-group.buttons-group-stretched .btn {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}
.buttons-group.buttons-group-center {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.buttons-group.buttons-group-right {
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.buttons-group-stretched.wide-ellipsis .btn {
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child,
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child ~ .btn {
  width: calc(50% - 15px);
}
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child,
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child ~ .btn {
  width: calc(33% - 15px);
}
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child,
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child ~ .btn {
  width: calc(25% - 15px);
}
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child,
.buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child ~ .btn {
  width: calc(20% - 15px);
}
.buttons-group-stretched.wide-ellipsis .btn span {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.buttons-group-stretched.wide-adaptive .btn {
  white-space: normal;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child,
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child ~ .btn {
  width: calc(50% - 15px);
}
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child,
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child ~ .btn {
  width: calc(33% - 15px);
}
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child,
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child ~ .btn {
  width: calc(25% - 15px);
}
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child,
.buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child ~ .btn {
  width: calc(20% - 15px);
}
.buttons-group-stretched.wide-adaptive .btn span {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
@media (max-width: 767px) {
  .buttons-group.buttons-group-responsive {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column-reverse;
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child ~ .btn {
    width: calc(100% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child ~ .btn {
    width: calc(100% - 15px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(2):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(3):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(4):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child,
  .buttons-group-stretched.wide-ellipsis .btn:nth-last-child(5):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(2):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(3):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(4):first-child ~ .btn {
    width: calc(50% - 15px);
  }
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child,
  .buttons-group-stretched.wide-adaptive .btn:nth-last-child(5):first-child ~ .btn {
    width: calc(50% - 15px);
  }
}
.select2 {
  width: 100%!important;
}
.select2-container .select2-selection {
  color: #000000;
  font-size: calc((100vw - 320px)/(1920 - 320) * (16 - 14) + 14px);
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  line-height: 16px;
  border: 1px solid rgba(161, 173, 205, 0.5);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.select2-container .select2-selection:not([size]):not([multiple]):not(textarea) {
  height: 25px;
  line-height: 33px;
}
.select2-container .select2-selection:hover {
  background: #fafafa;
  border-color: rgba(69, 85, 129, 0.5);
}
.select2-container .select2-selection:focus {
  background: #fafafa;
  border-color: rgba(56, 70, 106, 0.5);
}
.select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  line-height: inherit;
  padding-left: 0.75rem;
}
.select2-container .select2-selection.select2-selection--multiple .select2-selection__choice {
  line-height: 5px;
}
.select2-container .select2-selection .select2-selection__arrow {
  height: 16px;
}
.select2-container .select2-selection .select2-search--inline .select2-search__field {
  height: 8px;
}
.select2-container.select2-container--disabled .select2-selection {
  color: #333333;
  border-color: #e6e6e6 !important;
  background: #e6e6e6 !important;
  -webkit-opacity: 0.5 !important;
  -khtml-opacity: 0.5 !important;
  -moz-opacity: 0.5 !important;
  opacity: 0.5 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50) !important;
  filter: alpha(opacity=50) !important;
}
.select2-container + .form-control-feedback,
.select2-container + .help-block {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.select2-container .select2-results__option[aria-disabled="true"] {
  display: none;
}
.select2-container .select2-results__option--highlighted[aria-selected] {
  background: rgba(107, 126, 172, 0.05);
  font-weight: 700;
  color: #223a90;
}
.select2-container .select2-dropdown {
  border-color: rgba(161, 173, 205, 0.5);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
  border-top: 1px solid rgba(161, 173, 205, 0.5);
}
.form-material .select2-container .select2-selection {
  position: relative;
  height: 18px;
  padding: 0;
  color: #000000;
  background: transparent !important;
  border: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  -webkit-transition: all 0.3s ease 0.3s ease;
  -moz-transition: all 0.3s ease 0.3s ease;
  -o-transition: all 0.3s ease 0.3s ease;
  transition: all 0.3s ease 0.3s ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-material .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}
.form-group[class*="has-"] {
  cursor: default;
}
.form-group[class*="has-"] .select2-container .select2-selection {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.form-group.has-success .select2-container .select2-selection {
  border-color: #32A737;
}
.form-group.has-warning .select2-container .select2-selection {
  border-color: #E18700;
}
.form-group.has-danger .select2-container .select2-selection,
.form-group.has-error .select2-container .select2-selection {
  border-color: #F25C62;
}
.text-black {
  color: #000000 !important;
}
.text-primary {
  color: #223a90 !important;
}
.text-gray {
  color: rgba(0, 0, 0, 0.5) !important;
}
.no-resize {
  resize: none;
}
.img-responsive {
  max-width: 100%;
}
body.enter header {
  background: transparent;
  padding-top: 24px;
  border-bottom: 0;
}
body.enter .logo {
  margin: 34px auto 10px;
  height: 45px;
}
body .scrollable .mCustomScrollBox,
body .scrollable .mCSB_container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
header {
  background: #223a90;
  position: relative;
  text-align: center;
  padding: 1px 0;
  color: #ffffff;
  z-index: 4;
}
footer {
  -webkit-box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  position: relative;
  color: #A1ADCD;
  min-width: 80px;
  z-index: 3;
}
footer a {
  color: #A1ADCD;
}
footer a.active {
  color: #223a90;
}
.logo {
  margin: 13px auto 12px;
  display: inline-block;
  height: 24px;
}
.logo svg {
  height: 100%;
  width: 100%;
}
.slogan {
  font-family: 'Open Sans Condensed', serif;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}
.top-menu .item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-align: center;
  position: relative;
  max-width: 100px;
  min-width: 65px;
}
.top-menu .item:hover {
  color: #ffffff;
}
.top-menu .item:hover .icon {
  color: rgba(255, 255, 255, 0.5);
}
.top-menu .item:hover .icon svg * {
  fill: rgba(255, 255, 255, 0.5);
}
.top-menu .item .icon {
  margin: 0 auto 7.5px;
  display: block;
  color: #ffffff;
  height: 24px;
  width: 24px;
}
.top-menu .item .icon svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #ffffff;
}
.top-menu .item .text {
  line-height: 1.4;
  display: block;
}
.top-menu .item .counter {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background: #F25C62;
  text-align: center;
  position: absolute;
  line-height: 15px;
  margin-left: 4px;
  min-width: 15px;
  font-size: 65%;
  padding: 0 5px;
  color: #ffffff;
  height: 15px;
  z-index: 1;
  left: 50%;
  top: -3px;
}
.top-menu-wrapper {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 360px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  margin: auto;
  width: 100%;
}
.bottom-menu {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
}
.bottom-menu .item {
  text-decoration: none;
  position: relative;
  color: #A1ADCD;
  display: block;
}
.bottom-menu .item:hover,
.bottom-menu .item.active {
  color: #223a90;
}
.bottom-menu .item:hover .icon svg *,
.bottom-menu .item.active .icon svg * {
  fill: #223a90;
}
.bottom-menu .item .icon {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 0 auto 5px;
  display: block;
  height: 24px;
  width: 24px;
}
.bottom-menu .item .icon svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #A1ADCD;
}
.bottom-menu .item .text {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 1.4;
  display: block;
}
.bottom-menu .item .counter {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 0 5px 0 4px;
  background: #F25C62;
  text-align: center;
  position: absolute;
  line-height: 15px;
  min-width: 15px;
  font-size: 80%;
  color: #ffffff;
  height: 15px;
  z-index: 1;
  right: 0;
  top: 0;
}
.bottom-menu .item-button {
  background-color: transparent;
  border: none;
  width: 100%;
}
.bottom-menu-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 9px 15px 6px;
  font-size: 71.4286%;
  text-align: center;
  max-width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.form {
  background: #ffffff;
  max-width: 560px;
  margin: 0 auto;
  padding: 15px;
}
.form-invert form {
  max-width: 560px;
  margin: auto;
}
.form-invert .form-group[class*="has-"] .form-control-feedback,
.form-invert .form-group[class*="has-"] + .help-block {
  display: block;
}
.form-invert .form-control-feedback,
.form-invert .help-block {
  display: none;
}
.form-invert a {
  color: #A1ADCD;
}
.form-with-btn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.form-with-btn .form-wrapper_content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.form-with-btn .form-wrapper_btn .buttons-group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px;
}
.forgot {
  margin-top: -15px;
  text-align: right;
  line-height: 1.2;
}
.login-link {
  margin-top: 15px;
  text-align: left;
  line-height: 1.2;
}
.filter {
  position: relative;
}
.filter:hover svg * {
  fill: #223a90;
}
.filter svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #223a90;
  fill: #A1ADCD;
}
.filter .changes {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background: #223a90;
  text-align: center;
  position: absolute;
  line-height: 16px;
  padding: 0 5px;
  font-size: 80%;
  color: #ffffff;
  height: 15px;
  width: 15px;
  right: -7px;
  z-index: 1;
  top: -7px;
}
.articles-list_item__wrapper {
  border-bottom: 2px solid rgba(161, 173, 205, 0.5);
  padding-bottom: calc(1em - 2px);
  overflow: hidden;
}
.articles-list_item__box {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #000000;
}
.articles-list_item__img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 20px;
  overflow: hidden;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  width: 20%;
}
.articles-list_item__img .img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 100%;
}
.articles-list_item__img img {
  width: 100%;
}
.articles-list_item__text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.articles-list_item__date {
  letter-spacing: 0.7px;
  line-height: 1.16667;
  margin-bottom: 3px;
  font-size: 85.714%;
  opacity: 0.5;
}
.articles-list_item__title {
  letter-spacing: 0.666667px;
  line-height: 1.14286;
  font-weight: 700;
  display: block;
}
.articles-list_item__info {
  letter-spacing: 0.7px;
  line-height: 1.16667;
  font-size: 71.4286%;
  margin-top: 3px;
  opacity: 0.5;
}
.articles-list_item__desc {
  letter-spacing: 0.7px;
  line-height: 1.16667;
  margin-bottom: 3px;
  font-size: 85.714%;
}
.articles-list_item__tag-list {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 34px;
}
.articles-list_item__tag-list___wrapper {
  padding-top: 4px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.articles-list_item__tag-list___more {
  padding-top: 14px;
  float: right;
}
.articles-list_item__tag-list___more:after {
  background: #A1ADCD;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 71.4286%;
  margin-left: -6px;
  line-height: 20px;
  padding: 0 10px;
  display: block;
  content: '...';
  color: #ffffff;
  height: 20px;
}
.articles-list_item__tag-list.open {
  height: auto;
}
.articles-list_item__tag-list.open .articles-list_item__tag-list___more {
  display: none;
}
.articles-list_item__tag {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none !important;
  background: #A1ADCD;
  margin: 10px 10px 0 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 71.4286%;
  line-height: 20px;
  padding: 0 10px;
  color: #ffffff;
  height: 20px;
}
.articles-list_item__tag:last-child {
  margin-right: 0;
}
.articles-list_item__tag:hover {
  background: #223a90;
  color: #ffffff;
}
.articles-list_item__direction {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 8px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}
.articles-list_item__direction:before {
  border: 1px solid rgba(161, 173, 205, 0.8);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  background: transparent;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-right: 3px;
  border-left: 0;
  border-top: 0;
  content: '';
  height: 9px;
  width: 9px;
}
.articles-list_item.open .articles-list_item__wrapper {
  padding-bottom: 0;
  border-bottom: 0;
}
.articles-list_item.open .articles-list_item__box {
  display: block;
}
.articles-list_item.open .articles-list_item__img {
  margin-bottom: 12px;
  width: 100%;
}
.articles-list_item.open .articles-list_item__img .img {
  padding-bottom: 54%;
}
.articles-list_item.open .articles-list_item__text {
  /*.list-element:last-child {
						border-bottom: 0;
					}*/
}
.articles-list_item.open .articles-list_item__text > * + * {
  margin-top: 8px;
}
.articles-list_item + .articles-list_item {
  margin-top: 1em;
}
.top-nav {
  position: relative;
}
.top-nav .item svg * {
  fill: #A1ADCD;
}
.top-nav .item + .item {
  margin-left: 16px;
}
.top-nav-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 12px;
  right: 0;
}
.search-box {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: transparent;
  position: relative;
  text-align: left;
  max-height: 25px;
  overflow: hidden;
  min-height: 0;
  width: 24px;
  z-index: 1;
}
.search-box.active {
  margin: -12px -55px 0 0;
  background: #ffffff;
  max-height: 100vh;
  padding: 15px;
}
.search-box.active .search-form {
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  padding-bottom: 6px;
  margin-bottom: 20px;
  width: 100%;
}
.search-box.active .search-form_input {
  width: 100%;
}
.search-box.active .search-form_label {
  display: none;
}
.search-box.active .search-form_btn {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-left: 10px;
  cursor: pointer;
  display: block;
  outline: none;
}
.search-box.active .search-form_btn:hover svg * {
  fill: #223a90;
}
.search-box.active .search-header {
  margin: -15px -15px 12px;
  padding: 13px 15px;
  height: 51px;
}
.search-box.active .search-results {
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  opacity: 1;
}
.search-box.page {
  min-height: auto !important;
  max-height: none;
  width: 100%;
  padding: 0;
  margin: 0;
}
.search-box.page .search-form {
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  padding-bottom: 6px;
  margin-bottom: 20px;
  width: 100%;
}
.search-box.page .search-form_input {
  width: 100%;
}
.search-box.page .search-form_label {
  display: none;
}
.search-box.page .search-form_btn {
  margin-left: 10px;
  cursor: pointer;
  display: block;
}
.search-box.page .search-form_btn:hover svg * {
  fill: #223a90;
}
.search-box.page .search-form_btn svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #223a90;
  fill: #A1ADCD;
}
.search-box.page .search-header {
  padding: 13px 15px;
  max-width: 560px;
  margin: 0 auto;
  color: #ffffff;
  height: 51px;
}
.search-box.page .search-results {
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  opacity: 1;
}
.search-form {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search-form_input {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: top;
  outline: none;
  height: 25px;
  padding: 0;
  border: 0;
  width: 0;
}
.search-form_label {
  margin: 0;
}
.search-form_btn {
  background: transparent;
  outline: none;
  display: none;
  padding: 0;
  border: 0;
}
.search-header {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #223a90;
  text-align: center;
  line-height: 25px;
  overflow: hidden;
  padding: 0 15px;
  margin: 0 -15px;
  width: 100vw;
  height: 0;
}
.search-header .back {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 1px solid #DADADA;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  background: transparent;
  position: relative;
  border-bottom: 0;
  border-right: 0;
  margin: 7px 0;
  float: left;
  height: 9px;
  width: 9px;
  padding: 0;
  left: auto;
}
.search-results {
  -webkit-transition: opacity 100ms ease 0.3s ease;
  -moz-transition: opacity 100ms ease 0.3s ease;
  -o-transition: opacity 100ms ease 0.3s ease;
  transition: opacity 100ms ease 0.3s ease;
  width: calc(100vw - 30px);
  -webkit-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
  opacity: 0;
}
.page-header {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #223a90;
  text-align: center;
  padding: 13px 15px;
  line-height: 25px;
  max-width: 560px;
  min-height: 51px;
  overflow: hidden;
  margin: 0 auto;
  color: #ffffff;
  width: 100vw;
}
.page-header.profile {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-header.profile .page-header-title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  height: 1.2em;
}
.page-header .back {
  text-align: center;
  margin-left: -5px;
  display: block;
  height: 23px;
  width: 23px;
  float: left;
}
.page-header .back:before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 1px solid #DADADA;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  background: transparent;
  position: relative;
  margin: 7px auto;
  border-bottom: 0;
  border-right: 0;
  display: block;
  content: '';
  height: 9px;
  width: 9px;
  padding: 0;
  left: auto;
}
.page-header .back:hover:before {
  border-color: #A1ADCD;
}
.page-header-img {
  margin: 3px 20px 3px 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 64px;
  flex: 0 0 64px;
  width: 64px;
}
.page-header-img .img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
}
.page-header-text {
  color: #A1ADCD;
  line-height: 1.2;
  overflow: hidden;
}
.page-header-title {
  font-size: 128.5715%;
  font-weight: 700;
  color: #ffffff;
}
.list-element {
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  text-decoration: none !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0 7px;
  min-height: 49px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.list-element__button {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(161, 173, 205, 0.5);
  width: 100%;
}
.list-element_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.list-element:not(.readonly):hover {
  border-bottom: 1px solid rgba(218, 218, 218, 0.5);
}
.list-element.open .deploy:before {
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.list-element.add:hover .list-element_icon:not(:last-child) svg * {
  fill: rgba(0, 0, 0, 0.5);
}
.list-element.add:hover .list-element_content .line {
  color: rgba(0, 0, 0, 0.5);
}
.list-element.disabled .list-element_icon {
  opacity: 0.25;
}
.list-element.disabled .list-element_content {
  opacity: 0.25;
}
.list-element.disabled .list-element_direction {
  opacity: 0.75;
}
.list-element_icon {
  padding-right: 6px;
}
.list-element_icon svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #223a90;
}
.list-element_content {
  text-decoration: none !important;
  max-width: calc(100% - 30px);
  padding: 0 5px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0;
  fill: #223a90;
}
.list-element_content .line {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: calc(100% + 10px);
  line-height: 1.16667;
  font-size: 128.5715%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #000000;
}
.list-element_content .line.left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.list-element_content .line.info {
  font-size: 85.714%;
}
.list-element_content .line.title + .line {
  margin-top: 2px;
}
.list-element_content .line.info + .title,
.list-element_content .line.info + .info {
  margin-top: 6px;
}
.list-element_content .line .form-group {
  width: 100%;
}
.list-element_content .item {
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  padding: 0 5px;
  border: 0;
}
.list-element_content .item:first-child {
  padding-left: 0;
}
.list-element_content .item:last-child {
  padding-right: 0;
}
.list-element_content .item.icon svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #223a90;
}
.list-element_content .item.icon[download] {
  margin-top: -3px;
}
.list-element_content .item.icon:hover svg * {
  fill: #A1ADCD;
}
.list-element_content .item.small {
  font-size: 83.334%;
}
.list-element_content .item.big {
  font-size: 116.667%;
}
.list-element_content .form-material {
  padding: 0;
  border: 0;
}
.list-element_content .control-label {
  line-height: 37px;
  height: 37px;
}
.list-element_content input.form-control {
  height: 18px !important;
  margin-top: 19px;
  padding: 0;
  border: 0;
}
.list-element_content textarea.form-control {
  min-height: 18px;
  margin-top: 6px;
  padding: 0;
  border: 0;
}
.list-element_content input.item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  width: 100%;
}
.list-element_content input.item:focus {
  font-size: 133.3334%;
  color: #000000;
}
.list-element_content textarea.item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
  resize: none;
  width: 100%;
}
.list-element_content .title {
  letter-spacing: 0.666667px;
  font-weight: 700;
  font-size: 100%;
}
.list-element_content .status {
  font-size: 83.334%;
}
.list-element_content .status.new {
  color: #00a1ff;
}
.list-element_content .status.inprogress {
  color: #E18700;
}
.list-element_content .status.complete {
  color: #32A737;
}
.list-element_content .status.rejected {
  color: #F25C62;
}
.list-element_content + .list-element_icon {
  padding: 0 0 0 6px;
}
.list-element_content + .list-element_icon.favorite {
  padding-left: 10px;
}
.list-element_content + .list-element_icon.favorite:hover svg * {
  stroke: #223a90;
}
.list-element_content + .list-element_icon.favorite.active svg * {
  stroke: #223a90;
  fill: #223a90;
}
.list-element_content + .list-element_icon.favorite svg * {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  stroke: #A1ADCD;
  fill: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.list-element_content + .list-element_icon svg * {
  fill: #A1ADCD;
}
.list-element_content .value .control-label {
  font-size: 55.7%;
}
.list-element_direction {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 8px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}
.list-element_direction:before {
  border: 1px solid rgba(161, 173, 205, 0.8);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  background: transparent;
  margin-right: 3px;
  border-left: 0;
  border-top: 0;
  content: '';
  height: 9px;
  width: 9px;
}
.list-element_direction.follow:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.list-element_direction.deploy:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.list-element_direction.switch:before {
  display: none;
}
.list-element_direction.switch label {
  padding: 0 0 31px 51px;
  position: relative;
  margin: 0;
}
.list-element_custom {
  padding: 10px 0;
}
.tabs {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #D0D6E6;
  background: #D0D6E6;
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.tabs .item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(34, 58, 144, 0.5);
  background: transparent;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  font-weight: 700;
  padding: 0 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin: 0;
  border: 0;
}
.tabs .item:hover {
  color: #223a90;
}
.tabs .item.active {
  background: #ffffff;
  color: #223a90;
}
.tab-content_item {
  display: none;
}
.tab-content_item.active {
  display: block;
}
.table-action-column {
  width: 270px;
}
.notifications .list-element {
  padding: 10px 0 9px;
}
.notifications .list-element.new .title {
  padding-left: 11px;
  position: relative;
}
.notifications .list-element.new .title:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #F25C62;
  position: absolute;
  margin-top: -3px;
  content: '';
  height: 6px;
  width: 6px;
  top: 0.5em;
  left: 0;
}
.poll-list .list-element {
  padding-bottom: 13px;
}
.poll-list .list-element_content .line + .line {
  margin-top: 8px;
}
.poll-list .list-element + .list-element {
  margin-top: 5px;
}
.transport .list-element {
  position: relative;
  margin-left: 50px;
}
.transport .list-element_icon {
  border: 1px solid #A1ADCD;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  margin-top: -3px;
  padding: 7px;
  height: 32px;
  width: 32px;
  left: -50px;
  z-index: 1;
  top: 50%;
}
.transport .list-element_icon svg {
  vertical-align: top;
  height: 100%;
  width: 100%;
}
.transport .list-element + .list-element {
  margin-top: 6px;
}
.transport .list-element:not(:first-child):before {
  background: #D0D6E6;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  position: absolute;
  opacity: 0.7;
  height: 110%;
  bottom: 50%;
  content: '';
  left: -34px;
  z-index: 0;
  width: 1px;
}
.transport .list-element:not(:first-child):after {
  background: #D0D6E6;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  opacity: 0.7;
  bottom: 100%;
  content: '';
  left: -34px;
  height: 6px;
  width: 6px;
  z-index: 0;
}
.profile-img {
  padding-top: 35px;
}
.profile-img .profile-img-wrapper {
  margin: 0 auto 15px;
  max-width: 175px;
  width: 50%;
}
.profile-img .img {
  background: #DADADA url("../img/photo.svg") center no-repeat;
  background-size: 38%;
  padding-bottom: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
}
.profile-img .img[style] {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.profile-img .actions {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  max-width: 290px;
  padding: 20px 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.profile-img .actions b {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #223a90;
  line-height: 1;
  display: block;
}
.profile-img .actions svg {
  height: 25px;
}
.profile-img .actions svg * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  fill: #223a90;
}
.profile-img .actions .change:hover b,
.profile-img .actions .remove:hover b {
  color: #A1ADCD;
}
.profile-img .actions .change:hover svg *,
.profile-img .actions .remove:hover svg * {
  fill: #A1ADCD;
}
.profile-img label {
  cursor: pointer;
  margin: 0;
}
.modal .modal-body .form-control:not([size]):not([multiple]):not(textarea) {
  height: 37px;
}
.article-slider .slick-list {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.slick-dots {
  text-align: center;
  list-style: none;
  padding: 0;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li.slick-active button {
  background: #223a90;
}
.slick-dots li button {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #A1ADCD;
  font-size: 0px;
  margin: 0 2px;
  height: 4px;
  width: 4px;
  padding: 0;
  border: 0;
}
.error-page {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 100% !important;
  flex: 1 1 100% !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.error-page .error-wrapper {
  text-align: center;
  padding: 0 15px;
  color: #ffffff;
}
.error-page .error-code {
  font-size: 514.2855%;
  font-weight: 900;
  line-height: 1.1;
}
.error-page .error-title {
  text-transform: uppercase;
  margin-bottom: 7.04225vh;
  font-weight: 700;
}
.error-page .error-text {
  margin-bottom: 7.04225vh;
  font-size: 128.5715%;
  font-weight: 700;
}
.error-page .btn {
  padding: 0.375rem 2rem;
}
.select2-selection {
  border-bottom: none!important;
}
.discounts-slider {
  padding-right: 21px;
  margin: 0 auto;
}
.discounts-slider + * {
  margin-top: 30px;
}
.discounts-slider .slick-list {
  overflow: visible;
}
.discounts-slider .slick-track {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  width: auto !important;
}
.discounts-slider .slick-slide {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-box-shadow: inset 0 0 0 1px #DADADA;
  -moz-box-shadow: inset 0 0 0 1px #DADADA;
  box-shadow: inset 0 0 0 1px #DADADA;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #ffffff;
  position: absolute;
  overflow: hidden;
  height: inherit;
  left: -35px;
  z-index: 0;
  opacity: 0;
  bottom: 0;
}
.discounts-slider .slick-slide.slick-active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  position: relative;
  height: auto;
  z-index: 3;
  opacity: 1;
  left: 0;
}
.discounts-slider .slick-slide.slick-active .slide {
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  opacity: 1;
}
.discounts-slider .slick-slide.slick-active + * {
  -webkit-transform: scale(0.88235);
  -moz-transform: scale(0.88235);
  -ms-transform: scale(0.88235);
  -o-transform: scale(0.88235);
  transform: scale(0.88235);
  z-index: 2;
  opacity: 1;
  left: 10%;
  top: 0;
}
.discounts-slider .slick-slide.slick-active + * + * {
  -webkit-transform: scale(0.765);
  -moz-transform: scale(0.765);
  -ms-transform: scale(0.765);
  -o-transform: scale(0.765);
  transform: scale(0.765);
  z-index: 1;
  opacity: 1;
  left: 20%;
  top: 0;
}
.discounts-slider .slick-slide.slick-active + * + * + * {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  left: 30%;
  top: 0;
}
.discounts-slider .slick-slide > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.discounts-slider .slick-dots {
  text-align: left;
  margin: 0;
}
.discounts-slider .slide {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: inset 0 0 0 1px #A1ADCD;
  -moz-box-shadow: inset 0 0 0 1px #A1ADCD;
  box-shadow: inset 0 0 0 1px #A1ADCD;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #223a90;
  padding-bottom: 68.775%;
  background-size: cover;
  -webkit-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
  position: relative;
  overflow: hidden;
  display: block;
  opacity: 0;
  z-index: 1;
}
.discounts-slider .slide:after {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(68.75%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  position: absolute;
  content: '';
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.discounts-slider .slide-info {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  padding: 9px 10px;
  min-height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
}
.discounts-slider .slide-cat {
  line-height: 1.2;
  font-weight: 700;
  font-size: 87.5%;
}
.discounts-slider .slide-title {
  margin-bottom: 2px;
  line-height: 1.2;
  font-weight: 700;
  font-size: 87.5%;
}
.discounts-slider .slide-desc {
  line-height: 1.172;
  font-weight: 400;
  font-size: 75%;
}
.discounts-slider .slide img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 100%;
  width: 100%;
  z-index: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.discounts-list {
  margin: 30px 0;
}
.discounts-list.collapsible.minimized > [class*=-item]:not(:first-child) {
  margin-top: -100px;
}
.discounts-list.partners-list > [class*=-item] {
  margin-bottom: 20px;
}
.discounts-list .collapsible-item {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: inset 0 0 0 1px #A1ADCD;
  -moz-box-shadow: inset 0 0 0 1px #A1ADCD;
  box-shadow: inset 0 0 0 1px #A1ADCD;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #223a90;
  padding-bottom: 68.775%;
  background-size: cover;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.discounts-list .collapsible-item:after {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(68.75%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 68.75%, rgba(0, 0, 0, 0.5) 100%);
  position: absolute;
  content: '';
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.discounts-list .collapsible-item-info {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  padding: 9px 10px;
  min-height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
}
.discounts-list .collapsible-item-cat {
  line-height: 1.2;
  font-weight: 700;
  font-size: 87.5%;
}
.discounts-list .collapsible-item-title {
  margin-bottom: 2px;
  line-height: 1.2;
  font-weight: 700;
  font-size: 87.5%;
}
.discounts-list .collapsible-item-desc {
  line-height: 1.172;
  font-weight: 400;
  font-size: 75%;
}
.discounts-list .collapsible-item img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 100%;
  width: 100%;
  z-index: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.discounts-list .collapsible-item + [class*=-item] {
  margin-top: 20px;
}
@media (max-width: 1050px) {
  body {
    /*&.modal-open {
		header, .top-menu, .box {
			.translate(-100%, 0);
		}
	}*/
  }
  header,
  .top-menu,
  .box {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .top-menu {
    font-size: 71.4286%;
  }
  .top-menu-wrapper {
    padding: 13.5px 0 10px;
  }
  .form {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
  }
  .form-invert {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #ffffff;
  }
  .form-invert label,
  .form-invert .form-control,
  .form-invert .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
    color: #ffffff;
  }
  .form-invert .form-check input.form-check-input + .form-check-label {
    color: #A1ADCD;
  }
  .form-invert .form-check input.form-check-input + .form-check-label:before {
    -webkit-box-shadow: inset 0 0 0 10px #223a90;
    -moz-box-shadow: inset 0 0 0 10px #223a90;
    box-shadow: inset 0 0 0 10px #223a90;
    border: 2px solid #ffffff;
    background: #223a90;
  }
  .form-invert .form-check input.form-check-input:checked + .form-check-label {
    font-weight: 700;
    color: #ffffff;
  }
  .form-invert .form-check input.form-check-input:checked + .form-check-label:before {
    -webkit-box-shadow: inset 0 0 0 3px #223a90;
    -moz-box-shadow: inset 0 0 0 3px #223a90;
    box-shadow: inset 0 0 0 3px #223a90;
  }
  .form-invert .form-check input.form-check-input:checked + .form-check-label:after {
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
  .form-invert .btn-primary {
    border-color: #ffffff;
    background: #ffffff;
    color: #223a90;
  }
  .form-invert .buttons-group {
    margin-bottom: -7.5px;
  }
  .form-invert .value label {
    color: #ffffff;
  }
  .articles-list_item__img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 24.052%;
    flex: 0 0 24.052%;
  }
  .modal.side-menu {
    display: block !important;
    padding: 0 0 58px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    right: auto;
    width: 100%;
    left: 100%;
    z-index: 5;
    opacity: 1;
  }
  .modal.side-menu.show {
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
  .modal.side-menu .modal-dialog {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    height: 100%;
    margin: 0;
  }
  .modal.side-menu .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    height: 100%;
    border: 0;
  }
  .modal.side-menu .modal-header {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #223a90;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 14px;
    padding: 0 15px;
    display: block;
    color: #ffffff;
  }
  .modal.side-menu .modal-header .close {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border: 1px solid #DADADA;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    background: transparent;
    position: relative;
    border-bottom: 0;
    border-right: 0;
    margin: 20px 0;
    float: left;
    height: 9px;
    width: 9px;
    padding: 0;
    left: auto;
  }
  .modal.side-menu .modal-header .close:before,
  .modal.side-menu .modal-header .close:after {
    display: none;
  }
  .modal.side-menu h3,
  .modal.side-menu .h3 {
    margin-bottom: 20px;
  }
  .modal.side-menu .form-check + .form-check {
    margin-top: 20px;
  }
  .modal.side-menu .form-check-label {
    display: block;
  }
  .modal .modal-body {
    padding: 13px 15px 11px;
  }
  .modal-dialog {
    margin: 10px 15px;
  }
  .modal-backdrop {
    -webkit-transition: all 100ms ease 0.3s ease;
    -moz-transition: all 100ms ease 0.3s ease;
    -o-transition: all 100ms ease 0.3s ease;
    transition: all 100ms ease 0.3s ease;
    -webkit-transition-delay: 0ms;
    -o-transition-delay: 0ms;
    transition-delay: 0ms;
  }
  .modal-backdrop:not(.filtered) {
    -webkit-transition-delay: 300ms;
    -o-transition-delay: 300ms;
    transition-delay: 300ms;
    bottom: 100%;
  }
  .search-box.active {
    min-height: calc(100vh - 58px);
  }
  .list-element_content .line.info {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .list-element_content .status {
    margin-left: auto;
  }
  .error-page .btn {
    display: block;
  }
  footer {
    z-index: 6;
  }
}
@media (min-width: 1051px) {
  header {
    border-bottom: 1px solid rgba(245, 246, 250, 0.25);
    padding: 18px 0;
  }
  .top-menu-wrapper {
    padding: 0 0 26px;
  }
  .logo {
    height: 32px;
  }
  .form {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 0 auto 23px;
    padding: 30px;
  }
  .form-invert form {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #ffffff;
    padding: 30px;
  }
  .form-invert .form-check input.form-check-input + .form-check-label {
    color: #A1ADCD;
  }
  .form-invert .form-check input.form-check-input + .form-check-label:before {
    -webkit-box-shadow: inset 0 0 0 10px #ffffff;
    -moz-box-shadow: inset 0 0 0 10px #ffffff;
    box-shadow: inset 0 0 0 10px #ffffff;
    border: 2px solid #223a90;
  }
  .form-invert .form-check input.form-check-input:checked + .form-check-label {
    font-weight: 700;
    color: #223a90;
  }
  .form-invert .form-check input.form-check-input:checked + .form-check-label:before {
    -webkit-box-shadow: inset 0 0 0 3px #ffffff;
    -moz-box-shadow: inset 0 0 0 3px #ffffff;
    box-shadow: inset 0 0 0 3px #ffffff;
  }
  .mCSB_container {
    padding-bottom: 1px;
  }
  .search-box.active {
    -webkit-box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.05);
    box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.05);
    max-height: 400px;
    margin: -15px 0 0;
    width: 360px;
  }
  .search-box.page.active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    margin: 0;
  }
  .search-box.page .search-header {
    margin-bottom: -13px;
    display: block;
  }
  .search-header {
    display: none;
  }
  .search-results {
    width: 100%;
  }
  .modal-body {
    padding: 1rem 3rem;
  }
  .discounts-slider .slide[href]:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  .discounts-list .collapsible-item[href]:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  .discounts-list:not(.wide) {
    margin: 30px -15px 0;
  }
  .discounts-list:not(.wide) h3,
  .discounts-list:not(.wide) .h3 {
    margin-left: 15px;
  }
  .discounts-list:not(.wide) .collapsible-item {
    margin: 0 15px 30px !important;
    padding-bottom: 34.3875%;
    width: calc(50% - 30px);
    display: inline-block;
  }
}
@media (min-width: 1051px) {
  body.enter {
    background: #223a90;
  }
  header {
    margin-bottom: 26px;
  }
  footer {
    background: transparent;
    position: absolute;
    padding-top: 94px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 100%;
    bottom: 0;
    left: 0;
    top: 0;
  }
  .bottom-menu {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
  }
  .bottom-menu::-webkit-scrollbar {
    width: 0;
  }
  .bottom-menu-wrapper {
    font-size: 85.714%;
    display: block;
    padding: 0 15px 0 0;
  }
  .bottom-menu .item {
    border-left: 2px solid #223a90;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 0 0 19px;
    margin: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bottom-menu .item.active {
    border-left-color: #ffffff;
    color: #ffffff;
  }
  .bottom-menu .item:hover {
    color: #ffffff;
  }
  .bottom-menu .item:hover .icon svg * {
    fill: #ffffff;
  }
  .bottom-menu .item.active .icon svg * {
    fill: #ffffff;
  }
  .bottom-menu .item .icon {
    margin: 0 10px;
  }
  .bottom-menu .item .text {
    white-space: nowrap;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .page-header {
    margin-top: -26px;
  }
}

.articles-list_item__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}
.articles-list_item__line .news-like {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 12px;
  margin-left: 15px;
}
.articles-list_item__line .news-like span {
  white-space: nowrap;
  margin-right: 8px;
}

.news-feed {
  margin: 0 -30px;
}
.news-feed__item {
  padding: 20px 30px;
  border-bottom: 5px solid #F5F6FA;
}
.news-feed__item:last-of-type {
  border-bottom: none;
}
.news-feed__img {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100%;
  margin-bottom: 15px;
}
.news-feed__title {
  font-weight: 700;
  color: #000000;
  margin-bottom: 5px;
  letter-spacing: 0.7px;
  line-height: 1.2;
  display: block;
}
.news-feed__desc {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 12px;
  color: #000000;
  max-height: 56px;
  overflow: hidden;
  letter-spacing: 0.7px;
}
.news-feed__desc.show {
  max-height: 10000px;
}
.news-feed__more {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.7px;
  color: #A1ADCD;
  cursor: pointer;
  margin-top: 4px;
}
.news-feed__more:hover {
  color: #5e73aa;
}
.news-feed__footer {
  margin-top: 10px;
  position: relative;
}
.news-feed__footer .news-like {
  position: absolute;
  right: 0;
  top: 0;
}
.news-feed__count {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.7px;
  color: #000000;
}
.news-feed__count span {
  font-weight: 700;
  margin-left: 4px;
}
.news-feed__date {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.7px;
  color: #000000;
  opacity: 0.5;
  margin-top: 6px;
}

.news-like {
  cursor: pointer;
}
.news-like span {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
  letter-spacing: 0.7px;
}
.news-like svg path {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-like:hover svg path {
  stroke: rgba(242, 92, 98, 0.4);
  fill: rgba(242, 92, 98, 0.4);
}
.news-like.active svg path {
  stroke: #F25C62;
  fill: #F25C62;
}


@media (max-width: 1050px) {
  .news-feed {
    margin: 0 -15px;
  }
  .news-feed__item {
    padding: 20px 15px;
  }
}

.hidden-input {
  position: absolute;
  left: -170px;
  opacity: 0;
}

.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.tag-item {
  display: flex;
  align-items: center;
  color: #000000;
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
  margin: 2px;
  padding: 5px;
  min-width: 0;
}
.tag-item img {
  width: 19px;
  height: 19px;
}
.tag-item-title {
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
img + .tag-item-title {
  padding-left: 6px;
}

.something-broke-block {
  text-align: center;
}
.something-broke-block__action {
  max-width: 700px;
  margin: 0 auto;
}
.something-broke-block__subtitle {
  font-size: 100%;
  line-height: 2;
}
.something-broke-block__link {
  color: #223a90;
  border-color: #ffffff;
  background: #ffffff;
  border-radius: 0.25rem;
  width: 100%;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 700;
}

@import "functions";

body {
  overflow: hidden;
}

.box {
  overflow: auto !important;

  height: calcHeight(120px);

  &.newsone,
  &.contacts,
  &.job-descriptions,
  &.events,
  &.notifications-list,
  &.polls-list,
  &.profile,
  &.medical,
  &.applications,
  &.pollone,
  &.partners,
  &.education,
  &.referral-program,
  &.bonus-program {
    height: calcHeight(145px);
  }
  &.news {
    height: calcHeight(215px);
  }
  &.profile-wrap {
    height: calcHeight(190px);
  }
  &.user-agreement {
    height: calcHeight(120px);
  }
}
.box-full-size {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.Toastify__toast {
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;

  &--error {
    background: #f25c62;
  }
}

.logo img {
  height: 100%;
  width: 100%;
}

.enter {
  background: transparent;
  padding-top: 24px;
  border-bottom: 0;

  .logo {
    margin: 34px auto 10px;
    height: 45px;
  }
}

.filter {
  cursor: pointer;
}

.react-datepicker__tab-loop {
  position: absolute;
  z-index: 1111;

  .withtimes {
    width: 328px;
  }
  .onlyMonthYear {
    width: 219px;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #223a90;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #223a90;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #182967;
  color: #ffffff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #182967;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #223a90;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #182967;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #223a90;
}

.app-header {
  background: #223a90;
  border-bottom: 1px solid #2f353a;
}

.app-body {
  .main {
    padding-top: 15px;
    height: calc(100vh - 55px);

    .form-group {
      label {
        font-weight: bolder !important;
      }

      &.has-danger .form-select-control>div:first-child {
        border: 1px solid #F25C62;
      }

      &.required label:after {
        content:"*";
        margin-left: 2px;
      }

      select.form-control {
        height: initial !important;
      }
    }

    .form-check .required:after {
      content:"*";
      margin-left: 2px;
    }
  }
}

.custom-pagination {
  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

.height-initial {
  height: initial !important;
}

.profile-img .actions {
  & > span {
    cursor: pointer;
    margin: 0;
  }
  & svg g {
    transition: none;
  }
}

.like-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none !important;
}

.modal .modal-body {
  overflow: initial !important;
}

.overflow-initial {
  overflow: initial !important;
}

.table-title {
  background-color: #f0f0f0;
  font-weight: bold
}

.form-settlement-sheets {
  max-width: 700px !important;
}

.ws-pw {
  white-space: pre-wrap;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.modal-select {
  max-width: 404px;
  z-index: 4;
  position: relative;
}

.form-select {
  z-index: 4;
  position: relative;
}

.notifications-modal {
  z-index: initial;
  position: absolute;

  &.has-blocking {
    z-index: 1050;
    background-color: rgba(38, 68, 116, 0.5);
  }

  .notification-modal .modal-content {
    z-index: 1050;
  }
}

.list-element-input {
  margin: 8px 0 7px 0 !important; // как .list-element
}

.has-danger .form-error-block {
  margin: 2px 0 -12px 0 !important;
}

.list-element_content.custom-lec {
  max-width: calc(100% - 64px);
}
/** для блоков с отступами между элементами. например кнопки в одной строке */
.space-between-items > * {
  margin-left: 1rem;

  &:first-child {
    margin-left: initial;
  }
}

// для корректного вывода картинок из редактора
.articles-list_item__text img {
  max-width: 100%;
}

.btn-outline-none {
  outline: none !important;
}

.system-answers-card p:last-of-type {
  margin-bottom: 0;
}

.form-control:-webkit-autofill + label {
  height: auto;
  font-size: 10px;
  line-height: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.react-select__menu {
  z-index: 2 !important;
}

.wb-ba {
  word-break: break-word;
}

// Фикс обрезания из-за стиля bootstrap
.switch {
  width: auto;
  height: auto;
}

.input-hr {
  margin-top: 7px;
  margin-bottom: 2px;
  color: rgba(161, 173, 205, 0.5);
  border-top: 1px solid rgba(161, 173, 205, 0.5);
}

.form-material.no-border {
  border: none;
  padding-bottom: 0;
}

.card-block{
  border: 1px solid #c8ced3;
  border-radius: 4px;
  padding: 2px 6px;
  margin-bottom: 0.5rem;

  &.clickable {
    cursor: pointer;
  }
}

.construct-application-type-field--btn {
  font-size: 12px !important;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: white;
    }
  }
}
.construct-application-type-field--generate-checkbox {
  margin-top: 2.4rem;
}

.agree-link {
  text-decoration: underline;
  font-weight: bold;
}
.user-agreement-header h3 {
  color: #ffffff;
}

.line.info.left {
  flex-flow: wrap;
  .item {
    padding: 0 10px 0 0;
    &:first-child, &:last-child {
      padding: 0 10px 0 0;
    }
  }
}

.education .list-element_content .item {
  color: #000;
}

.enterprise-select__menu-list .enterprise-select__option {
  color: #686868;

  &--is-selected {
    color: #fff;
  }
}

.long-text-header {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.discounts-slider {
  .slide {
    border: 1px solid #a1adcd;
    border-radius: 10px;

    img {
      height: 100%;
    }
  }
}

.partner-no-img {
  background-image: url('../img/partner-no-img.png');
}
.cat-no-img {
  background-image: url('../img/cat-no-img.png');
}

.paralax_logo {
  height: 100%;
}

.upload-file-preview {
  height: 100px;
  img {
    height: 100%;
    max-width: 260px;
  }
}

.collapsible-item {
  border: 1px solid #a1adcd;
}

// Сдвиг для контента в скроллбаре что бы не было эпилепсии при скрытии полосы прокрутки.
.ScrollbarsCustom.trackYVisible {
  .ScrollbarsCustom-Content > *:last-child {
    &:after {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
    }
  }
}

// Увеличенная область контента что бы скролл всегда был, на коллапсирующих элементах
@media (max-width: 768px) {
  .collapse-wrapper {
    min-height: 90vh;
  }
}

.app-guide-custom {
  max-width: 520px !important;

  &-none {
    display: none !important;
  }
}

[data-tour-elem="controls"] {
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  gap: 10px;
}
[data-tour-elem="right-arrow"] {
  margin-left: 0 !important;
}
[data-tour-elem="left-arrow"] {
  margin-right: 0 !important;
}
@media (max-width: 576px) {
  .app-guide-custom {
    max-width: 360px !important;
  }
  [data-tour-elem="controls"] {
    justify-content: center !important;
  }
}
@media (max-width: 390px) {
  .app-guide-custom {
    max-width: 300px !important;
    left: 10px !important;
  }
  [data-tour-elem="controls"] {
    justify-content: center !important;
  }
}

@media (min-width: 1051px) {
  .d-flex-custom {
    display: flex !important;
  }
  .d-block-custom {
    display: block !important;
  }
  .d-none-custom {
    display: none !important;
  }
  .d-inline-custom {
    display: inline !important;
  }
}


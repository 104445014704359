.system-formgroup.form-group {
  .form-control {
    height: initial !important;
  }
}

.form-group label {
  font-weight: 800;
}

.ck.ck-content {
  min-height: 134px;
}

.nav-link.nav-link-non-active i {
  color: #73818f !important;
}

.ps--active-y .nav-item ul {
  margin-left: 10px !important;
}

//custom
.sidebar .nav-dropdown .nav-item .nav-link {
  padding-left: 40px;

  .nav-icon {
    display: none;
  }
}